
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.title {
  font-size: $gl-font-size-s2;
  font-weight: 600;
  margin-bottom: 20px;

  span {
    font-style: italic;
    font-weight: 700;
    border-bottom: 2px solid #FD613F;

  }
}

.img {
  max-width: 242px;
  margin: 0 auto;
}


.subtitle {
  font-size: 10px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.54);
  text-transform: uppercase;
  margin-bottom: 14px;
}

.list {
  @include list-unstyled();
  font-size: $gl-font-size-s2;
}

.list__item + .list__item {
  margin-top: $gl-spacer-xs;
}

.congrats {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 125%;
  /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: -0.02em;

  color: #292929;

  display: flex;
  align-items: center;

}
