
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  @include button-reset();
  // width: 192px;

  background: $theme-light-lt;
  cursor: pointer;
  padding: $gl-spacer-xxs;
  box-shadow: 0px 14px 34px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  transition: 0.5s transform;
  display: block;
  color: inherit;

  &:not(.active) .like {
    transition: 0.5s background-color;
  }

  &.active .like {
    background: rgba(253, 97, 63, 0.2);
    color: #FD613F;
  }

}

.thumb {
  padding-top: math.div(216, 176)  * 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: $gl-spacer-s;
}

.img {
  overflow: hidden;
  border-radius: 16px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.title {
  font-size: $gl-font-size-xs;
  font-weight: 600;
  margin-bottom: 0;

  $lh: $gl-line-height-base;
  line-height: $lh;
  max-height: $lh * 2em;
  overflow: hidden;
}

.price {
  font-weight: 500;
  font-size: 10px;
  color: $theme-dark-lt;
}

.like {
  @include button-reset();
  @include size(24px);

  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;

  background: rgba(0, 0, 0, 0.25);
  color: $theme-light-lt;

}

.container:hover {
  transform: translate(0, -8px);

  &:not(.active) .like {
    background: #FD613F;
  }
}

@include desktop {
  .container {
    padding-bottom: $gl-spacer-s;
  }

  .title {
    font-size: $gl-font-size-s2;

  }

  .like {
    @include size(32px);

    svg {
      @include size(16px);
    }
  }

  .price {
    font-size: $gl-font-size-xs;
  }
}
