
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    

.container {
  background: linear-gradient(270deg, #5f5a83 0%, #424158 100.35%);
  color: rgba($theme-light-lt, 0.61);
  padding: 64px 0;
  position: relative;
  // z-index: $gl-z-index-2;
}


.compact {
  padding: $gl-spacer-xs 0;
}

.header {
  margin-bottom: $gl-spacer-m;
}

.title {
  font-size: $gl-font-size-h5;
  margin-bottom: 0;
  color: $theme-light-lt;
  font-weight: 700;
}

.subtitle {
  font-size: $gl-font-size-xxs;
  color: rgba($theme-light-lt, 0.69);
}

* + .subtitle {
  margin-top: 6px;
}

@include desktop {
  .container {
    padding: 80px 0;
  }

  .header {
    margin-bottom: $gl-spacer-l;
  }

  .subtitle {
    font-size: 15px;
  }

  .compact {
    padding: $gl-spacer-m 0;
  }

  .title {
    font-size: 28px;
  }
}
