
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $gl-z-index-modal;
  overflow: hidden;
}

.overlay {
  background:  rgba($theme-dark-dkr, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $gl-z-index-1;
}

.header {
  padding: $gl-spacer-s $gl-spacer-s $gl-spacer-m;
  text-align: right;
}

.content {
  padding: 0 $gl-spacer-m $gl-spacer-m;
  flex: 1;

  @include desktop {
    padding: 0 $gl-spacer-l $gl-spacer-l;
  }
}

.inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;


  width: 766px;
  z-index: $gl-z-index-2;
  background: $theme-light-lt;

  max-width: 100%;


  @include desktop {
    border-radius: 16px;
    height: 95%;
    top: 50%;
    left: 50%;
    bottom: auto;
    right: auto;
    max-height: 100%;
    transform: translate(-50%, -50%);
  }
}
