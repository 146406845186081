
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  margin-top: $gl-spacer-xl;
  margin-bottom: $gl-spacer-l;

  @include desktop {
    margin-top: $gl-spacer-xl;
    margin-bottom: $gl-spacer-xl;
  }

  .top {
    background: rgba(0, 0, 0, 0.03);
    color: $clr-dark-font;

    &:hover,
    &:focus {
      background: rgba(0,0,0,0.05);
    }
  }

  .hidden .top {
    display: none;
  }
}
