
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  margin-top: $gl-spacer-s;
  width: 100%;
}

.submit {
  border-radius: 100px;
  @include mobile {
    width: 100%;
    margin-top: $gl-spacer-s;
  }
  @include desktop {
    width: 101px;
  }
  height: 48px;

  padding: 12px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.input {
  padding: 12px 16px;
  width: 245px;

  height: 48px;

  display: flex;
  align-items: center;

  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  letter-spacing: -0.02em;

  /* Grayscale/Dark-lt (54%) */

  color: rgba(0, 0, 0, 0.54);
}
