
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.loading-bar {
  background-color: $theme-primary;
  height: 2px;

  width: 100%;
  z-index: $gl-z-index-max;
}

.positioned {
  position: absolute;
  top: 0;
  left: 0;

  &.outside {
    top: auto;
    bottom: 100%;
  }
}

.bottom {
  top: auto;
  bottom: 0;

  &.outside {
    bottom: auto;
    top: 100%;
  }
}

