
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.wrapper {
  @include desktop {
    position: absolute;
    @include size(100%);

    background: $theme-light-lt;
    opacity: 0.7;
    z-index: $gl-z-index-pre-max - 1;
  }
}
