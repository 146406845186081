
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  position: relative;

  &::before {
    z-index: $gl-z-index-0;
    background: #7983A3;
    opacity: 0.4;
    filter: blur(25px);
    border-radius: 30px;
    position: absolute;
    left: 7.6%;
    right: 7.6%;
    bottom: - 12.5%;
    top: 17.5%;
    content: '';
  }
}

.side {
  &::before {
    background: #576079;
    filter: blur(30px);
    left: -13%;
    right: 13%;
    bottom: - 18%;
    top: 12%;
  }
}

.img {
  position: relative;
  z-index: $gl-z-index-1;
}

.inner {
  overflow: hidden;
  border-radius: 12px;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: $theme-light-lt;
  opacity: 0.7;
  border-radius: 0 12px 12px 0;

  &--full {
    border-radius: 12px;
  }
}

