
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.wrapper {
  padding: 0;
  border-radius: 0;
  @include desktop {
    border-radius: 28px;
    height: auto;
  }
  width: 540px;
  max-height: 829px;
}
.content {
  padding: 0;
  position: relative;
  @include mobile {
    overflow: auto;
  }
}

.header {
  // position: absolute;
  // top: 0;
  // z-index: -1;

  color: $theme-light-lt;
  background: url('/static/assets/images/pages/dashboard/summer_bg_mobile.png') no-repeat;
  background-size: cover;
  background-position: left top;

  @include desktop {
    background: url('/static/assets/images/pages/dashboard/summer_bg.png') no-repeat;
    background-size: cover;
    background-position: center;
  }
  background-blend-mode: soft-light;
  // mix-blend-mode: soft-light;
  height: 229px;
  height: calc(100% / 3.14);
  @include desktop {
    height: 360px;
  }
  width: 100%;
  overflow: auto;

  @include media('<=415px') {
    background-size: 110% auto;
    background-position: 40% 0;
    height: 308px;
  }
  @include media('<=400px') {
    background-size: 110% auto;
    background-position: 40% 0;
    height: 275px;
  }
}

.close_wrapper {
  // background: #FFFFFF;
  background: rgba(255, 255, 255, 0.1);
  // display: flex;
  @include size(24px);
  position: absolute;
  top: $gl-spacer-m;
  right: $gl-spacer-m;
  min-width: 24px;;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  // fill: ;
  color: rgba(255, 255, 255, 0.28);
  svg {
    stroke-width: 2px;
  }

}

.event_info {
  padding-left: 208px;
  padding-top: 71px;
  padding-right: 16px;
  @include desktop {
    padding-left: 260px;
    padding-top: 86px;
    padding-right: 40px;
  }
  // white-space: nowrap;
}




.price {
  font-size: 14px;
  @include desktop {
    font-size: 20px;
  }
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0;
  text-align: left;
}

.footer {
  overflow: auto;
  padding: 23px 48px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.how_it_works {
  width: 100%;
}

.hiw_title {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.50;

  text-align: left;
  @include desktop {
    text-align: center;
  }
  letter-spacing: -0.02em;
  color: #121212;
}

.hiw_list {
  @include list-ordered;
  margin: $gl-spacer-m 0 0 $gl-spacer-xs;

  &__item {
    text-align: left;
    font-weight: normal;
    font-size: $gl-font-size-s;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.69);
    padding-left: $gl-spacer-xxs;

    & + & {
      margin-top: $gl-spacer-s;
    }
  }
}

.sub {
  font-size: $gl-font-size-s;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.02em;

  // pseudo elem or border bottom
  text-decoration: underline;
  @include button-reset;
  color: $theme-primary;
}

.spots_left_wrapper {
  // margin-bottom: $gl-spacer-s;
  margin-bottom: 14px;
}

.spots_left {
  font-weight: bold;
  font-size: 17px;
  line-height: 1.50;
  letter-spacing: -0.02em;
  color: #FD613F;
}

.iam_in {
  margin-bottom: $gl-spacer-s;
  width: 189px;
  text-align: center;
  padding: 12px 32px;

  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.02em;
  // TODO: use variables
  color: #FFF;
}

.not_for_now {
  @include button-reset;
  font-weight: normal;
  font-size: $gl-font-size-xs;
  line-height: 1.5;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.54);
}

.card_wrapper {
  position: relative;
}

.card {
  width: 140px;
  @include desktop {
    width: 180px;
  }
  position: absolute;
  right: -25px;
  // top: 35.5%;
  top: -65px;
  right: 15px;
  @include desktop {
    top: -65px;
    right: -25px;
  }
  transform: rotate(-15deg);

  // &:after {
  //   position: absolute;
  //   right: -25px;
  //   top: 35.5%;
  //   width: 100%;
  //   height: 100%;

  //   content: ' ';
  //   background: #576079;
  //   opacity: 0.4;
  //   filter: blur(30px);
  //   border-radius: 30px;
  //   // transform: rotate(-15deg);
  // }
}

@include desktop{

  @include media('height<=750px') {

    .modal {
      &__container {
        display: flex;
        justify-content: center;
        overflow: auto;
      }

      &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        height: auto;
      }

      &__inner {
        position: relative;
        transform: none;
        top: auto;
        left: auto;
        max-height: none;
        height: auto;
      }
    }
  }

}


.title {
  font-size: $gl-font-size-h5;
  font-weight: 700;
  line-height: $gl-line-height-sm;
  margin-bottom: $gl-spacer-xxs;

  &__mark {
    color: #AFFFF2;
  }

  @include desktop {
    font-size: $gl-font-size-h3;
    margin-bottom: $gl-spacer-s;
  }
}

.subtitle {
  font-size: $gl-font-size-3xs;


  @include desktop {
    font-size: $gl-font-size-s;
  }
}

.hint {
  color: rgba(0, 0, 0, 0.69);
  font-size: $gl-font-size-xs;
  margin: $gl-spacer-m 0 $gl-spacer-xxs;

  @include desktop {
    text-align: center;
  }
}
