
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
///* ========================================================================
//   #Toolbar
//   ======================================================================== */

// A fixed toolbar with dropdowns

// Container
$navbar-item-size: 64px;
$navbar-bg: $theme-light-lt;
$navbar-color: $theme-dark-dk;
$navbar-z-index: $gl-z-index-pre-max;
$navbar-padding-x: $gl-spacer-s;
$navbar-padding-y: $gl-spacer-s;
$navbar-overlay-bg: $gl-overlay-bg;
// Dropdown
$navbar-dd-padding-x: $gl-spacer-m;
$navbar-dd-padding-y: $gl-spacer-m;
$navbar-dd-font-size: $gl-font-size-lg;
$navbar-dd-item-hover: $theme-light;
$navbar-dd-item-spacing: 10px;
$navbar-dd-section-spacing: 40px;
// Separator
$navbar-separator-color: $theme-light-dk;
$navbar-separator-size: 1px;

// Label
$navbar-label-font-size: $gl-font-size-4xs;
$navbar-label-color: $theme-dark;
$navbar-spacing: 5px; // spacing between the icon and the label

.container {

  // TODO: HOTFIX. We need to move mobile toolbar
  // to the bottom after the footer
  position: absolute;
  z-index: $navbar-z-index;
  bottom: 0;
  left: 0;
  // min-height: $navbar-item-size;


  .inner {
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    //
    background-color: $navbar-bg;
    color: $navbar-color;
  }

  .item {

    display: flex;
    align-items: center;
    justify-content: center;


  }

  .link {
    @include button-reset();

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: $navbar-item-size;
    max-width: $navbar-item-size;
    width: 100%;
    position: relative;

    @include customLink($navbar-label-color, $navbar-color);

    @at-root {
      .item:first-child & {
        color: $navbar-color;
      }
    }
  }

  .svg {
    stroke: currentColor;
  }

  .round {
    @include size(40px);

    color: $theme-light-lt;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $theme-dark-dk;
  }

  .overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $navbar-overlay-bg;
  }

  .label {
    text-transform: uppercase;
    font-size: $navbar-label-font-size;
    font-weight: 500;
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }

  .count {
    z-index: $gl-z-index-1;
  }

  // DROPDOWN MENU
  .dd {
    position: fixed;
    left: 0;
    right: 0;
    text-align: left;
    //
    bottom: $navbar-item-size;
    background: $navbar-bg;
    padding: rem($navbar-dd-padding-y) 0;
    // The dropdown's height must be no more than {the window's height - the bar's height}
    max-height: calc(100% - #{$navbar-item-size});
    overflow: auto;

    &__section {
      @include list-unstyled();
      // Spacing between sections
      & + & {
        margin-top: rem(math.div($navbar-dd-section-spacing, 2) - $navbar-separator-size);
        padding-top: rem(math.div($navbar-dd-section-spacing, 2) - $navbar-separator-size);
        border-top: $navbar-separator-size solid $navbar-separator-color;
      }
    }

    &__item {
      font-size: rem($navbar-dd-font-size);
      // Spacing between items
      & + & {
        margin-top: $navbar-dd-item-spacing;
      }
    }

    &__link {
      @include button-reset();

      display: block;
      text-align: left;
      width: 100%;
      line-height: 1;
      text-decoration: none;
      padding: 10px rem($navbar-dd-padding-x);
      color: $navbar-color;
      position: relative;

      &:hover,
      &:focus,
      &.is-active {
        text-decoration: none;
        background: $navbar-dd-item-hover;
        color: $navbar-color;
      }
    }


    &__count {
      position: absolute;
      top: 50%;
      left: math.div(rem($navbar-dd-padding-x), 2);
      transform: translate(0, -50%);
    }
  }
}


// Hide the bottom toolbar, when the mobile keyboard is visible
// @include media("height<350px", "portrait") {
//   display: none;
// }

