
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.wrapper {
  border: 1px solid $clr-light-blue;
  background: rgba($theme-light-lt, 0.6);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  text-align: center;
}

.title {
  font-weight: 600;
  font-size: 17px;
  line-height: 125%;
  color: $clr-dark-font;
  margin-bottom: 16px;

  @include media(">=md") {
    font-size: 20px;
    margin-bottom: 24px;
  }
}

.button {
  margin-bottom: 16px;
  max-width: 300px;

  @include media(">=md") {
    max-width: 200px;
  }
}

.sign-up {
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #121212;

  @include media(">=md") {
    font-size: 15px;
  }
}

.link {
  font-weight: 500;
  margin-left: 4px;
}
