
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.popup {

  @include desktop {
    width: 720px;
  }

  @include mobile {
    div.popup__inner {
      padding-top: $gl-spacer-s;
      box-shadow: 0 -5px 40px rgba(0,0,0,0.3);
    }

    div.popup__overlay {
      background: none;
    }
  }
}


.button {
  @include mobile {
    width: 100%;
    text-align: center;
  }
}

.title {
  font-size: $gl-font-size-s2;
  text-align: center;
  margin-bottom: $gl-spacer-3xs;


}

.subtitle {
  margin-bottom: $gl-spacer-s;
  font-size: $gl-font-size-xs;
  color: $theme-dark;
  text-align: center;
}

@include desktop {
  .title {
    font-size: $gl-font-size-xlg;
  }

  .subtitle {
    font-size: $gl-font-size-s2;
  }
}


div.slider {
  overflow: visible;
  margin: -30px -24px;


  .slide {
    width: 101px;
    height: auto;
    padding: 30px 0;
  }
}
