
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$banner-height: 41px;
$top: 72px;

div.container {
  position: fixed;
  top: $top;
  left: 50%;
  transform: translateX(-50%);
  z-index: $gl-z-index-modal - 1;
  background: $theme-light-lt;
  box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $clr-dark-font;

  border-radius: 12px;


  width: 90%;
  max-width: 327px;
  padding: $gl-spacer-xs $gl-spacer-s;

  @include desktop {
    padding: $gl-spacer-xs $gl-spacer-m;
    max-width: 536px;
    border-radius: 57px;
  }

  &--banner-offset {
    top: $top + $banner-height;
  }



  .btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: $gl-spacer-m;
    padding-right: $gl-spacer-m;

    @include mobile {
      padding: 5px $gl-spacer-s;
      font-size: $gl-font-size-xxs;
    }
  }

  .text {
    font-weight: 600;
    font-size: $gl-font-size-xxs;
    line-height: 1;
    letter-spacing: -0.02em;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include desktop {
      font-size: $gl-font-size-s;
    }
  }
}
