
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
div.modal_inner {
  padding: 0;
  border-radius: 30px 30px 0px 0px;
  padding-bottom: $gl-spacer-xs;
}

// .container {
//   // margin-bottom: $gl-spacer-m;
// }

.title {
  font-weight: 900;
  font-size: 17px;
  line-height: 1.25;
  text-align: center;

  color: #000000;
  margin: 0;
}

.header {
  margin: 0;
  mix-blend-mode: normal;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0px;
}

.body_row {
  padding: $gl-spacer-m $gl-spacer-m 0 $gl-spacer-m;
}

.btn {
  width: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  display: flex;
  align-items: center;

  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
}

.app_frame {
  @include size(48px);
  min-width: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;

  margin-right: $gl-spacer-s;
}

.browser_img {
  @include size(32px);
  min-width: 32px;
}

.cta_row {
  display: flex;
  align-items: center;
}
