
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  @include button-reset();

  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100px;
  position: relative;
  color: $theme-dark-dkr;
  font-weight: 600;
  line-height: $gl-line-height-xs;
  padding: 13px 16px;

  &:hover {
    background-color: rgba($theme-dark-dkr, 0.04);
  }
}

.with-icon {
  @include size(40px);
  padding: 0;
  color: $theme-dark;
}


.count {
  background-color: #f43c13;
  border-radius: 100px;
  color: $theme-light-lt;
  font-size: $gl-font-size-3xs;
  line-height: 10px;
  padding: 4px 6px;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: $gl-z-index-1;
}
