
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
/* ==========================================================================
   #Footer
   ========================================================================== */

// Top header navbar
$footer-bg: $theme-light-lt;
$footer-border-color: $theme-light-dk;
$footer-padding-y: 45px;
$footer-font-size: $gl-font-size-xs;
$footer-font-size-mobile: $gl-font-size-xxs;
$footer-color: $theme-dark;
$footer-hover-color: $theme-dark-dk;
// Nav
$footer-nav-padding-x: 10px;
$footer-nav-padding-y: 5px;
$footer-mobile-nav-padding-y: 8px;
$footer-mobile-bottom-margin: 24px;

.container {
  background-color: $footer-bg;
  font-size: $footer-font-size;
  color: $footer-color;
  padding: 60px 0;
  border-top: $gl-border-width-base solid $footer-border-color;
  display: block;
  // margin-top: $gl-footer-margin-top;

  &_banner {
    padding-top: 0;

    @include desktop {
      padding-top: 60px;
    }
  }


  @include phone {
    font-size: $footer-font-size-mobile;
  }
}

.mobile-banner {
  margin-bottom: 32px;

  @include desktop {
    display: none;
  }
}

.nav {
  @include list-unstyled();

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 (- rem($footer-nav-padding-x));
  align-items: flex-start;

  &__item {
    padding: rem($footer-nav-padding-y) rem($footer-nav-padding-x);
  }

  @include desktop {
    &.full {
      justify-content: space-between;
    }
  }

  &--bottom {
    justify-content: flex-start;
  }
}

.link {
  color: $footer-color;
  text-decoration: none;

  &:focus,
  &:hover,
  &.is-active {
    color: $footer-hover-color;
  }
}

.list {
  @include list-unstyled();

  &__item {
    line-height: 1.9em;
  }
}

.title {
  font-size: $gl-font-size-xs;
  margin-bottom: 1em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5pt;
  color: $theme-dark-dk;
}

.bottom {
  border-top: 1px solid $footer-border-color;
  padding-top: 1rem;
  margin-top: 2rem;
  font-size: 12px;
}

.social {
  &__item {
    color: $theme-dark;
    padding: 0 4px;
  }
}


.mobile {
  @include list-unstyled();
  text-align: center;

  margin: 0 (- rem($footer-nav-padding-x)) rem($footer-mobile-bottom-margin);
  font-weight: 700;
  text-transform: uppercase;
  font-size: $gl-font-size-xxs;
  letter-spacing: 0.5pt;

  &__item {
    padding: rem($footer-mobile-nav-padding-y) rem($footer-nav-padding-x);
  }
  
}


.mobile-bottom {
  display: block;
  text-align: center;
  margin-bottom: $gl-spacer-xl;

  &__item {
    display: inline-block;

    & + &:before {
      content: '|';
      padding: 0 2px;
      display: inline-block;
    }
  }
}

.mobile {
  @include desktop {
    display: none;
  }
}

.desktop {
  @include mobile {
    display: none;
  }
}