
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
// Header banner (e.g. final exams)
$text-color: rgba(255, 255, 255, 0.93);

.container {
  background: #282730;
  padding: 10px 0;
  font-size: $gl-font-size-s;
  display: block;
  color: $theme-light-lt;
  width: 100%;
  cursor: pointer;
  overflow-x: hidden;

  button.btn {
    // padding: 4px 10px;
    // font-size: $gl-font-size-s;
    @include button-reset();
    // border: 1px solid rgba(255, 255, 255, 0.93);
    // padding: 2px 16px;
    margin-left: $gl-spacer-s;
    font-weight: 600;
    font-size: $gl-font-size-xs;
    color: $text-color;
  }

  .btn_content {
    // padding: 1px 0px;
    padding: 0;
    border-bottom: 1px $text-color solid;
  }
}

.col {
  overflow-x: hidden;
  white-space: nowrap;
}


.icon {
  margin-right: -5px;
}

.text-icon {
  margin-right: $gl-spacer-xxs;
}
