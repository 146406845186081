
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.wrapper {
  min-height: 320px;
  align-items: center;
  text-align: center;
}

.add-course-btn {
  @include button-reset;

  @include plain-hover-focus {
    color: $theme-primary;
  }

  font-size: $gl-font-size-base;
  font-weight: normal;
  margin-top: $gl-spacer-l;
}

