$important: config(important);

@mixin cover($position: fixed) {
  position: $position;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  overflow-y: auto;
  transform: none;
}

@mixin overlay($z-index: $gl-z-index-modal) {
  @include cover();

  // background:  rgba($theme-dark-dkr, 0.6);
  z-index: $z-index;
}

@mixin fixedScroll() {
  -webkit-overflow-scrolling: touch $important;
  overflow: hidden $important;

  @at-root {
    html#{&} {
      height: 100% $important;
      // position: relative;
    }

    @include media('<sm') {
      body#{&} {
        height: 100% $important;
      }
    }
  }
}

