
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 374px;
  margin: auto;
}

.coupon {
  width: 219px;
}

.title {
  font-weight: bold;
  font-size: 22px;
  line-height: 1.25;
  /* identical to box height, or 27px */

  text-align: center;

  color: #292929;

  margin-top: $gl-spacer-s;
}

.description {
  font-size: 17px;
  line-height: 1.50;
  text-align: center;
  color: #616161;

  margin-top: $gl-spacer-xs;
}

a.btn {
  width: 229px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 64px;

  margin-top: $gl-spacer-m;
}

.link {
  @include button-reset;
  color: $theme-primary;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;

  @include hover-focus {
    color: $theme-primary;
    // text-decoration: underline;
  }

  margin-top: $gl-spacer-m;
}

.modal_wrapper {

  @include desktop {
    width: 478px;
  }
}
