
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    

$container-sizes: (
  sm: 455px,
  md: 576px,
  lg: 680px,
  xlg: 824px,
);

$modal-px: 60px;
$modal-py: $gl-spacer-xl;
$modal-mobile-px:$gl-spacer-m;
$modal-mobile-py: $gl-spacer-l;

.wrapper {
  @include overlay();
}

.overlay {
  background:  rgba($theme-dark-dkr, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $gl-z-index-1;
}


.title {
  font-weight: 500;
  margin-top: 0;
  text-align: center;
  margin-bottom: rem($gl-spacer-l);
  font-size: rem($gl-font-size-xlg);
}


.container {
  position: fixed;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  height: auto;
  max-height: 100%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  display: block;
  overflow-y: auto;
  background: $theme-light-lt;
  z-index: $gl-z-index-2;

  @include media('<sm') {
    max-width: none;
    width: 95%;

    &.mobile-cover {
      @include cover(absolute);
      border-radius: 0;
    }

    &.mobile-center {
      display: flex;
      align-items: center;
      justify-content: center;

      // TODO: refactor
      .toolbar {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: $gl-z-index-pre-max;
      }
    }
  }
}

@include media('>sm') {
  @each $name in map-keys($container-sizes) {
    .#{$name} {
      width: map-get($container-sizes, $name);
      max-width: 100%;
    }
  }
}

.toolbar {
  overflow: hidden;
  padding: $gl-spacer-m $gl-spacer-m 0;

  @at-root {
    .no-padding & {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: $gl-z-index-pre-max;
    }
  }
}

.inner{
  padding: $modal-mobile-py $modal-mobile-px;


  &.with-toolbar {
    padding-top: 0;
  }

  @include media('>sm') {
    padding: $modal-py $modal-px;


  }

  @at-root {
    .no-padding>& {
      padding: 0;
    }
  }
}




.tool {
  @include button-reset();
  opacity: 0.4;
  cursor: pointer;
  color: $theme-dark-dk;

  &:hover {
    opacity: 1;
  }
}

$step-dot-size: 6px;

.dots {
  white-space: nowrap;
}

.dot {
  width: $step-dot-size;
  height: $step-dot-size;
  border-radius: 50%;
  background-color: $theme-light-dk;
  display: inline-block;
  vertical-align: middle;

  &.is-active {
    background-color: $theme-primary;
  }

  & + & {
    margin-left: 2px;
  }
}

.modal-open {
  @include fixedScroll();
}
