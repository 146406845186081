
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$colors: (
  orange: (
    background: rgba(251, 239, 223, 1),
    color: #A15C00,
  ),
  grey-orange: (
    background: rgba(251, 239, 223, 0.24),
    color: #FFD4AD,
  ),

  grey: (
    background: rgba(0, 0, 0, 0.2),
    color: #FFD4AD,
  ),
);

$sizes: (
  xs: (
    padding: 4px 5px,
    font-size: 10px,
  ),
  sm: (
    padding: 6px 5px,
    font-size: $gl-font-size-xxs,
  ),
);

.container {
  border-radius: 32px;
  font-weight: 600;
  letter-spacing: -0.154px;
  line-height: 1;
}

@each $color, $opts in $colors {
  .container--#{'' + $color} {
    background: map-get($opts, background);
    color: map-get($opts, color);
  }
}

@each $size, $opts in $sizes {
  .container--#{'' + $size} {
    padding: map-get($opts, padding);
    font-size: map-get($opts, font-size);
  }
}

.badge_points {
  display: flex;
  align-items: center;
  margin-left: 2px;
  margin-bottom: 1px;
}
