
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.banner {
  display: block;
  padding: $gl-spacer-m;
  position: relative;

  background: $clr-white-ice url('/static/assets/images/banners/books.svg') no-repeat;
  background-position: right $gl-spacer-l bottom $gl-spacer-m;

  @include desktop {
    width: 378px;
    max-width: 100%;
    border-radius: $gl-radius-md ;
    padding: $gl-spacer-m $gl-spacer-l;
  }

  .text {
    font-weight: bold;
    line-height: $gl-line-height-sm;
    color: $theme-dark-dkr;
    font-size: $gl-font-size-base;
    margin-bottom: $gl-spacer-m;
  }

  .button {
    text-align: left;
    display: flex;
    align-items: center;
    padding: 5px 6px;
    border-radius: 6px;
    font-size: 8px;
    line-height: $gl-line-height-xs;

    &__text {
      display: block;
      font-weight: bold;
      font-size: $gl-font-size-xs;
      line-height: $gl-line-height-xs;
    }
  }


}

