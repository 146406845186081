
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    

.container {
  @include button-reset();

  align-items: center;
  color: inherit;
  display: flex;
  font-size: $gl-font-size-s;
  padding: $gl-spacer-s $gl-spacer-m;

  &:hover {
    background-color: rgba($theme-dark-dkr, 0.04);
  }

  .avatar {
    @include size(40px);
    flex-shrink: 0;

    margin-right: $gl-spacer-xs;
  }
}


.unread {
  background-color: #f6fafa;
}

.content {
  flex: 1;
  font-weight: normal;

  img {
    width: 15px;
    height: auto;
    display: inline-block;
    margin-bottom: -1px;
  }
}



.bullet {
  @include size(8px);

  background-color: $theme-alert;
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: $gl-spacer-xxs;
}

.date {
  color: rgba($theme-dark-dkr, 0.54);
  margin-left: $gl-spacer-3xs;
}
