// Lists

// Unstyled keeps list items block level, just removes default browser padding and list-style
@mixin list-unstyled {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin list-disc {
  list-style: disc;
}

@mixin list-ordered {
  list-style: decimal;
}

@mixin dot-list-inline-item($dot-margin: $gl-spacer-3xs ) {
  display: inline-block;
  vertical-align: middle;

  & + &:before {
    content: '·';
    display: inline-block;
    margin: 0 $dot-margin;
    vertical-align: middle;
  }
}
