
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$search-icon-cancel-size: 15px;
$search-icon-search-size: 18px;
$search-input-padding-left: $gl-spacer-xs + $search-icon-search-size +
  $gl-spacer-3xs;
$search-input-padding-right: $gl-spacer-xs + $search-icon-cancel-size +
  $gl-spacer-3xs;

.container {
  background-color: $theme-light-lt;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $gl-z-index-modal;
}

.header {
  align-items: center;
  background-color: $theme-light-lt;
  display: flex;
  padding: $gl-spacer-s $gl-spacer-s $gl-spacer-s $gl-spacer-m;

  &__autocomplete {
    flex: 1;
  }

  &__cancel {
    font-weight: 500;
    margin-left: $gl-spacer-3xs;
  }

  .icon-cancel {
    color: $theme-dark-dk;
    right: $gl-spacer-xs;
    opacity: 0.4;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    > svg {
      color: $theme-light-lt;
      fill: $theme-dark-dkr;

      circle {
        color: $theme-dark-dkr;
      }
    }
  }

  .icon-search {
    color: $theme-dark-dk;
    left: $gl-spacer-xs;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: $gl-z-index-1;
  }
}

.icon {
  left: $gl-spacer-xs;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.input-container {
  position: relative;
}

.input {
  background-color: #f3f3f3;
  border-radius: $gl-radius-lg;
  border: none;
  font-size: $gl-font-size-base;
  height: 36px;
  line-height: 36px;
  outline: none;
  padding: 0 $search-input-padding-right 0 $search-input-padding-left;
  width: 100%;
}

.item-container {
  list-style: none;
  margin: 0;
  overflow-y: auto;
}

.item {
  color: $theme-dark-dkr;
  display: flex;
  padding: $gl-spacer-s $gl-spacer-m;
  width: 100%;

  &:hover {
    background: rgba($theme-dark-dkr, 0.05);
  }

  &__icon {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    opacity: 0.35;
    margin-right: $gl-spacer-xs;
  }

  &__label {
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    > * {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__kind {
    flex-shrink: 0;
    margin-left: $gl-spacer-3xs;
    opacity: 0.54;
  }
}

.autocomplete {
  width: 100%;
}

.autocomplete-item {
  padding: 13px 30px;
}

.autocomplete-name {
  align-items: center;
  color: $theme-dark-dk;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.autocomplete-label {
  font-weight: normal;
  margin-left: $gl-spacer-3xs;
  opacity: 0.38;
}

.autocomplete-icon {
  opacity: 0.38;
}

.amp-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $gl-z-index-5;
}

.amp-overlay {
  background-color: transparent;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $gl-z-index-1;
}
