
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
button.container {
  padding: 0;
  min-width: 346px;
  background: unset;
  border: unset;
  @include hover-focus-active {
    background: unset;
    border: unset;
  }

  .btn {
    height: 40px;
    background: #F2F5F9;
    pointer-events: none;
    cursor: pointer !important;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.06);
    border-color: rgba(0,0,0,0.08);
  }
  .input {
    display: flex;
    align-items: center;
    min-width: 250px;
    font-size: $gl-font-size-s;

    &::placeholder {
      color: rgba(0,0,0,0.69);
    }
  }

  @include hover {
    .btn {
      background: darken(#F2F5F9, 2%);
      border-color: rgba(0,0,0,0.12);
    }
  }
}
