
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.menu {
  width: 100%;
  min-width: 0;
}

.container {
  position: static;
}
