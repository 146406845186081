
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include desktop {
    width: 390px;
    margin: 0 auto;
  }
}

.emoji_container {
  background: #DEF2E6;
  @include size(80px);
  min-width: 80px;
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: bold;
  font-size: 22px;
  line-height: 125%;
  text-align: center;
  color: #292929;

  margin-top: $gl-spacer-m;
}

.subtitle {
  font-size: 17px;
  line-height: 150%;
  text-align: center;
  color: #616161;

  margin-top: $gl-spacer-xs;
}

.description {
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #616161;

  margin-top: $gl-spacer-3xs;
}

.continue_btn {
  height: 48px;
  width: 193px;

  margin-top: $gl-spacer-m;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}

.link {
  @include button-reset;
  color: $theme-primary;
  @include hover-focus {
    color: $theme-primary;
  }
}

.container {
  button.back_btn {
    @include button-reset;
    padding: 0;
    background-color: transparent;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.4);
    &:hover {
      color: rgba(0, 0, 0, 0.54);
    }

    position: absolute;
    top: 24px;
    left: 24px;
  }
}
