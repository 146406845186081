
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  visibility: visible;



}

// TODO: HOTFIX. We need to move mobile toolbar
// to the bottom after the footer
// .with-toolbar {
//   @include mobile {
//     padding-bottom: 64px;
//   }
// }

.light {
  background: $theme-light-lt;
}

.colored {
  background: $clr-pale-grey;
}

.content {
  position: relative;
  flex: 1 1 auto;
}

