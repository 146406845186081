
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$dropdown-width: 376px;
$dropdown-max-height: 600px;
$menu-button-hover-bg: rgba($theme-dark-dkr, 0.04);
$search-default-color: $clr-white-dkr;

.container{
  background: $theme-light-lt;
  border-radius: 16px;
  border: 1px solid rgba($theme-dark-dkr, 0.07);
  box-shadow: 0 4px 20px rgba($theme-dark-dkr, 0.15);
  padding: 28px 0 16px;
  position: absolute;
  top: 0;
  right: -12px;
  width: $dropdown-width;
  z-index: $gl-z-index-5;
}

.label {
  font-size: $gl-font-size-base;
  font-weight: bold;
  line-height: 1.25;
  margin: 0 0 12px 24px;

  @include media('<=320px') {
    font-size: $gl-font-size-s2;
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;


}

.item {
  @include button-reset();

  align-items: center;
  border-radius: 0;
  color: inherit;
  display: flex;
  font-weight: normal;
  padding: 12px 24px;
  width: 100%;

  &:hover {
    background-color: $menu-button-hover-bg;
  }

  @include media('<=320px') {
    padding: $gl-spacer-xxs $gl-spacer-xxs;
  }

  &__text {
    display: inline-block;
    flex: 1;
  }

  &__title {
    color: $theme-dark-dkr;
    margin: 0;
    font-weight: 600;
    font-size: $gl-font-size-s2;
    line-height: $gl-line-height-sm;

    &--has-description {
      @include media('<=320px') {
        font-size: $gl-font-size-xs;
      }
    }
  }

  &__description {
    color: $theme-dark-alt;
    font-size: $gl-font-size-xs;
    line-height: $gl-line-height-sm;
    margin: $gl-spacer-4xs 0 0 0;

    @include media('<=320px') {
      font-size: $gl-font-size-xxs;
    }
  }

  &__icon-right {
    color: rgba($theme-dark-dkr, 0.3);
    flex-shrink: 0;
  }
}

