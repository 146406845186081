
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$dropdown-width: 376px;
$dropdown-max-height: 600px;
$menu-button-hover-bg: rgba($theme-dark-dkr, 0.04);
$search-default-color: $clr-white-dkr;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-weight: bold;
  font-size: 22px;
  line-height: 1.25;
  text-align: center;

  color: #292929;

  margin: $gl-spacer-m 0  $gl-spacer-xs;
}

.subtitle {
  font-size: 17px;
  line-height: 1.5;
  text-align: center;

  color: #616161;
}

.navbar__menu__item-container {
  list-style: none;
  margin: $gl-spacer-m 0 0;
  width: 100%;
  padding: 0;

  .navbar__menu__item {
    @include button-reset();

    align-items: center;
    border-radius: 0;
    color: inherit;
    display: flex;
    font-weight: normal;
    padding: $gl-spacer-s 0;
    width: 100%;

    border-top: 1px solid rgba(0, 0, 0, 0.065);

    &--last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.065);
    }

    &:hover {
      background-color: $menu-button-hover-bg;
    }

    @include media('<=320px') {
      padding: $gl-spacer-xxs $gl-spacer-xxs;
    }
  }
}

.navbar__menu__item__text {
  display: inline-block;
  flex: 1;
}

.navbar__menu__item__text__title {
  color: $theme-dark-dkr;
  margin: 0;
  font-weight: 600;
  font-size: $gl-font-size-s2;
  line-height: $gl-line-height-sm;

  &--has-description {
    @include media('<=320px') {
      font-size: $gl-font-size-xs;
    }
  }
}

.navbar__menu__item__text__description {
  color: $theme-dark-alt;
  font-size: $gl-font-size-xs;
  line-height: $gl-line-height-sm;
  margin: $gl-spacer-4xs 0 0 0;

  @include media('<=320px') {
    font-size: $gl-font-size-xxs;
  }
}

.navbar__menu__item__icon-right {
  color: rgba($theme-dark-dkr, 0.3);
  flex-shrink: 0;
}
